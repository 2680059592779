<template>
  <div id="app">
    <EphCalc/>
  </div>
</template>

<script>
import EphCalc from './components/EphCalc.vue'

export default {
  name: 'app',
  components: {
    EphCalc
  }
}
</script>

<style>
</style>
