<template>
  <div id="eph-calc" class="avenir">
  <div class="selection">
  <label for="planet">Planet:</label>
  <select v-model="planet" id="planet">
    <option value="su">Sun</option>
    <option value="me">Mercury</option>
    <option value="ve">Venus</option>
    <option value="mo">Moon</option>
    <option value="ma">Mars</option>
    <option value="ju">Jupiter</option>
    <option value="sa">Saturn</option>
    <option value="ur">Uranus</option>
    <option value="ne">Neptune</option>
    <option value="pl">Pluto</option>
    <option value="ch">Chiron</option>
  </select>
  </div>
  <div class="selection">
  <label for="sign">Sign:</label>
  <select v-model="sign" id="sign">
    <option value="1">Aries</option>
    <option value="2">Taurus</option>
    <option value="3">Gemini</option>
    <option value="4">Cancer</option>
    <option value="5">Leo</option>
    <option value="6">Virgo</option>
    <option value="7">Libra</option>
    <option value="8">Scorpio</option>
    <option value="9">Sagittarius</option>
    <option value="10">Capricorn</option>
    <option value="11">Aquarius</option>
    <option value="12">Pisces</option>
  </select>
  </div>
  <div class="selection">
  <label for="degree">Degree:</label>
  <input v-model="degree" id="degree" type="number" min="0" max="29">
  </div>
  <p v-if="!dates.length"><strong>There are no matching dates.</strong></p>
  <ul id="dates" v-if="dates.length">
    <li v-for="date in dates">{{ date }}</li>
  </ul>
  <p>Positions are at 00:00 TT. Built by 
    <a href="mailto:eli@formal.studio">eli@formal.studio</a>.
  </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      eph: null,
      planet: "su",
      sign: 1,
      degree: 0,
      months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    };
  },
  mounted() {
    fetch('./eph.json')
      .then((response) => response.json())
      .then((eph) => this.eph = eph);
  },
  computed: {
    key: function() {
      return this.planet + "," + this.sign + "," + this.degree;
    },
    dates: function() {
      if (this.eph === null) { return []; }
      let dates = this.eph[this.key];
      if (dates === undefined) { return []; }
      return dates.map((date) => {
        let parts = date.split(",");
        let month = this.months[Number(parts[1]) - 1];
        let year = "20" + parts[0];
        let day = parts[2];
        return month + " " + day + ", " + year;
      });
    }
  }
}
</script>

<style scoped>
</style>
